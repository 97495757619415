const validator = require("../application/validation_form")

document.addEventListener("turbolinks:load", function() {
  $("#registration-title").on("click", function(e) {
    $.each($("form.form-to-validate").find('.form-group'), function(index, element) {
      $formGroup = $(element);
      $input = $formGroup.find("input");
      $label = $formGroup.find("label")

      $input.removeClass("is-invalid")
      $label.removeClass("text-danger")
      $formGroup.find('small.form-text').text(null);
    });

  });

  $("#registration-form").each(function(options){
    $container = $(this)
    $form = $container.find("form.form-to-validate")

    $container.find("#btn-registration").on("click", function(e) {
      e.preventDefault();

      if (!$form.find('input[type="checkbox"].terms').is(":checked")) {
        console.log($("small#terms-error").text("Debes aceptar los términos y condiciones"))
        $("small#terms-error").text("Debes aceptar los términos y condiciones");
      }else{
        $("small#terms-error").text(' ');
        model_name = $form.data('model-name')

        $.ajax({
          url: $form.attr("action"),
          type: "POST",
          data: $form.serializeArray(),
          success: function(response) {
            if(response.success){
              Turbolinks.visit(response.url)
            } else {
              validator.bindErrorMessages($form, response.errors, model_name)
            }
          },
          error: function(error) {
            console.log(error);
          }
        });
      }
    });
  });
});
