document.addEventListener('turbolinks:load', function() {
  $("#shopping-modal").each(function(options){
    $shoppingModal = $(this)
    $paypalScript = $("#paypal-script")
    $title = $shoppingModal.find("#shopping-title-modal")
    $body = $shoppingModal.find(".modal-body")
    $firstStep = $body.find("#first-step-section")
    $secondStep = $body.find("#second-step-section")
    $thirdStep = $body.find("#third-step-section")
    loggedUser = $shoppingModal.data("email")
    loggedAdministrator = $shoppingModal.data("administrator")
    admin = $shoppingModal.data("admin")
    $hasAccountButton = $firstStep.find("#has-account-yes")
    $noAccountButton = $firstStep.find("#has-account-no")
    $validateButton = $secondStep.find("#validate-account-button")
    $validateInput = $secondStep.find("#account-email")
    $backButton = $secondStep.find("#back-button")
    $validationUrl = $shoppingModal.data("user-validation-url")
    $registerSaleUrl = $shoppingModal.data("sales-url")
    $returnUrl = $shoppingModal.data("cancel-url")

    $shoppingModal.on('hidden.bs.modal', function (event) {
      $title.html("")
      $firstStep.addClass("d-none")
      $secondStep.addClass("d-none")
      $thirdStep.addClass("d-none")
    })

    $shoppingModal.on('shown.bs.modal', function (event) {
      $button = $(event.relatedTarget)
      product = {
        name: $button.data("product-name"),
        price: $button.data("product-price"),
        id: $button.data("product-id")
      }
      $modalTitle = `<i class="fab fa-paypal"></i> Comprando producto ${product.name}`
      $title.append($modalTitle)

      $hasAccountButton.on("click", function(e){
        secondStepActive()
      })

      $noAccountButton.on("click", function(e){
        thirdStepActive(product)
      })

      $backButton.on("click", function(e){
        $formGroup = $validateInput.closest('.form-group');
        $validateInput.removeClass("is-invalid");
        $validateInput.val(null)
        $formGroup.find('small.form-text').text("")
        firstStepActive()
      })

      $validateButton.on("click", function(e){
        validateEmailRequest()
      })

      if (loggedUser) {
        thirdStepActive(product, loggedUser)
      } else if(loggedAdministrator) {
        secondStepActive()
      }else{
        firstStepActive()
      }
    })
  })
});

function validateEmailPresence($input, error_message = null){
  $formGroup = $input.closest('.form-group');
  $input.removeClass("is-invalid");
  $formGroup.find('small.form-text').text("")

  if(!$input.val() || error_message){
    $input.addClass("is-invalid");
    $formGroup.find('small.form-text').text((error_message || "El campo email no puede estar en blanco"));
    return
  }
}

function initializePaypal(product, user_email = null){
  $("#paypal-button-container").html("")
  paypal_sdk.Buttons({
    createOrder: function(data, actions) {
      return actions.order.create({
        purchase_units: [{
          amount: {
            value: product.price
          },
          reference_id: product.id,
          description: product.name
        }],
        application_context: {
          brand_name: "Klcula",
          cancel_url: $returnUrl
        }
      });
    },
    onApprove: function(data, actions) {
      return actions.order.capture().then(function(details) {
        registerSaleRequest(details, user_email)
      });
    }
  }).render('#paypal-button-container');
}


function firstStepActive(){
  $firstStep.removeClass("d-none")
  $secondStep.addClass("d-none")
  $thirdStep.addClass("d-none")
}

function secondStepActive(){
  $firstStep.addClass("d-none")
  $secondStep.removeClass("d-none")
  $thirdStep.addClass("d-none")
}

function thirdStepActive(product, user_email = null){
  $firstStep.addClass("d-none")
  $secondStep.addClass("d-none")
  $thirdStep.removeClass("d-none")
  $paypalScript.data("order-id", product.id)
  initializePaypal(product, user_email)
}

function validateEmailRequest(){
  validateEmailPresence($validateInput)

  $.ajax({
    url: $validationUrl,
    type: 'POST',
    data: {
      validation: {
        email: $validateInput.val()
      }
    },
    success: function(res) {
      if (res.presence) {
        thirdStepActive(product)
      } else {
        validateEmailPresence($validateInput, "No existe una cuenta con el email proporcionado")
      }
    },
    error: function(res) {
      validateEmailPresence($input, "Ha ocurrido un error, recarga la página")
    }
  });
}

function registerSaleRequest(response, user_email){
  $.ajax({
    url: $registerSaleUrl,
    type: 'POST',
    data: {
      product_sale: {
        user_email: $validateInput.val() || user_email || response.payer.email_address,
        payed_at: response.create_time,
        product_id: response.purchase_units[0].reference_id,
        payment_identifier: response.id,
        payment_response: response,
        administrator_id: admin
      }
    },
    success: function(res) {
      if (res.success) {
        Turbolinks.visit(res.url)
      } else {
        console.log(res)
      }
    },
    error: function(res) {
      console.log(res)
    }
  });
}