document.addEventListener("turbolinks:load", function() {
	$.each($(".accordion"), function( index, value ) {
    $accordionEl = $(value).filter(':not(.customjs)');

    if( $accordionEl.length < 1 ){
      return true;
    }

    $accordionEl.each( function(){
      let element			= $(this),
        elState			= element.attr('data-state'),
        elActive		= element.attr('data-active') || 1,
        elActiveClass	= element.attr('data-active-class') || '',
        elCollapsible	= element.attr('data-collapsible') || 'false',
        windowHash		= location.hash,
        accActive;

      elActive = Number( elActive ) - 1;

      if( typeof windowHash !== 'undefined' && windowHash != '' ) {
        accActive = element.find('.accordion-header'+ windowHash);
        if( accActive.length > 0 ) {
          elActive = accActive.index() / 2;
        }
      }

      element.find('.accordion-content').hide();

      if( elState != 'closed' ) {
        element.find('.accordion-header:eq('+ Number(elActive) +')').addClass('accordion-active ' + elActiveClass).next().show();
      }

      element.find('.accordion-header').off( 'click' ).on( 'click', function(){
        if( $(this).next().is(':hidden') ) {
          element.find('.accordion-header').removeClass('accordion-active ' + elActiveClass).next().slideUp("normal");
          let clickTarget = $(this);
          $(this).toggleClass('accordion-active ' + elActiveClass).next().stop(true,true).slideDown("normal", function(){
            if( ( $('body').hasClass('device-sm') || $('body').hasClass('device-xs') ) && element.hasClass('scroll-on-open') ) {
              $('html,body').stop(true,true).animate({
                'scrollTop': clickTarget.offset().top - ( SEMICOLON.initialize.topScrollOffset() - 40 )
              }, 800, 'easeOutQuad' );
            }
          });
        } else {
          if( elCollapsible == 'true' ) {
            $(this).toggleClass('acctitlec').next().stop(true,true).slideUp("normal");
          }
        }
        return false;
      });
    });
  });
	$window = $(window)
  $body = $("body")
  $header = $("#header")

  $normal_logo_url = $header.find(".standard-logo").data("normal-logo")
  if(!$body.hasClass("index") && $header.hasClass("dark")){
    $header.find(".logo-navbar").attr("src", $normal_logo_url)
  }
	stickyClass($window)
	stickyMenu($window)
	windowscroll($window)
  menufunctions()
  $window.on( 'resize', function() {
		let $thisWindow = $(this);
		stickyClass($thisWindow)
		stickyMenu($thisWindow)
		windowscroll($thisWindow)
	});

	swiperSliderMenu(true);
	revolutionSliderMenu(true)
  $.each($("#slider"), function( index, value ) {
    $sliderParallaxEl = $(".slider-parallax");
    $slider = $(value)
    $body = $("body")
    $header = $("#header")

    initSlider($sliderParallaxEl, $slider, $body, $header)
  })
  $('.header-wrap-clone').remove()
  goToTop()
});

let isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
}

function initSlider($sliderParallaxEl, $slider, $body, $header){
  sliderDimensions($sliderParallaxEl, $slider, $body)
  sliderParallax()
  sliderElementsFade()
}

function sliderDimensions($sliderParallaxEl, $slider, $body){
  let parallaxElHeight	= $sliderParallaxEl.outerHeight(),
  parallaxElWidth		= $sliderParallaxEl.outerWidth(),
  slInner				= $sliderParallaxEl.find('.slider-inner'),
  slSwiperW			= $slider.find('.swiper-wrapper'),
  slSwiperS			= $slider.find('.swiper-slide').first(),
  slFlexHeight		= $slider.hasClass('h-auto') || $slider.hasClass('min-vh-0');

  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
    setTimeout( function(){
      slInner.height( parallaxElHeight );
      if( slFlexHeight ) {
        parallaxElHeight = $sliderParallaxEl.find('.slider-inner').children().first().outerHeight();
        $sliderParallaxEl.height( parallaxElHeight );
        slInner.height( parallaxElHeight );
      }
    }, 500 );

    if( slFlexHeight ) {
      let slSwiperFC = slSwiperS.children().first();
      if( slSwiperFC.hasClass('container') || slSwiperFC.hasClass('container-fluid') ) {
        slSwiperFC = slSwiperFC.children().first();
      }
      if( slSwiperFC.outerHeight() > slSwiperW.outerHeight() ) {
        slSwiperW.css({ 'height': 'auto' });
      }
    }

    if( $body.hasClass('side-header') ) {
      slInner.width( parallaxElWidth );
    }

    if( !$body.hasClass('stretched') ) {
      parallaxElWidth = $wrapper.outerWidth();
      slInner.width( parallaxElWidth );
    }
  } else {
    slSwiperW.css({ 'height': '' });
    $sliderParallaxEl.css({ 'height': '' });
    slInner.css({ 'width': '', 'height': '' });
  }
}

function sliderParallaxOffset(){
  let $header = $("#header")
  let $pageTitle = $("#page-title")
  let $slider = $("#slider")
  let sliderParallaxOffsetTop = 0,
    headerHeight = $header.outerHeight();
  if( $body.hasClass('side-header') || $header.next('.include-header').length > 0 ) { headerHeight = 0; }
  if( $pageTitle.length > 0 ) {
    sliderParallaxOffsetTop = $pageTitle.outerHeight() + headerHeight;
  } else {
    sliderParallaxOffsetTop = headerHeight;
  }

  if( $slider.next('#header').length > 0 ) { sliderParallaxOffsetTop = 0; }

  return sliderParallaxOffsetTop;
}

function sliderParallaxSet( xPos, yPos, el ){
  if( el ) {
    el.style.transform = "translate3d(" + xPos + ", " + yPos + "px, 0)";
  }
}

function sliderParallax(){
	$body = $("body")
	$sliderParallaxEl = $('.slider-parallax')
  if( $sliderParallaxEl.length < 1 ) {
    return true;
  }

  let sliderParallaxEl = document.querySelector('.slider-parallax');
	let sliderParallaxElCaption = document.querySelector('.slider-parallax .slider-caption');
	let sliderParallaxElInner = document.querySelector('.slider-inner');

  let parallaxOffsetTop = sliderParallaxOffset(),
    parallaxElHeight = $sliderParallaxEl.outerHeight(),
    transform, transform2;

  xScrollPosition = window.pageXOffset;
  yScrollPosition = window.pageYOffset;

  if( ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) && !isMobile.any() ) {
    if( ( parallaxElHeight + parallaxOffsetTop + 50 ) > yScrollPosition ){
      $sliderParallaxEl.addClass('slider-parallax-visible').removeClass('slider-parallax-invisible');
      if ( yScrollPosition > parallaxOffsetTop ) {
        if( $sliderParallaxEl.find('.slider-inner').length > 0 ) {

          transform = ((yScrollPosition-parallaxOffsetTop) * -.4 );
          transform2 = ((yScrollPosition-parallaxOffsetTop) * -.15 );

          sliderParallaxSet( 0, transform, sliderParallaxElInner );
          sliderParallaxSet( 0, transform2, sliderParallaxElCaption );
        } else {
          transform = ((yScrollPosition-parallaxOffsetTop) / 1.5 );
          transform2 = ((yScrollPosition-parallaxOffsetTop) / 7 );

          sliderParallaxSet( 0, transform, sliderParallaxEl );
          sliderParallaxSet( 0, transform2, sliderParallaxElCaption );
        }
      } else {
        if( $sliderParallaxEl.find('.slider-inner').length > 0 ) {
          sliderParallaxSet( 0, 0, sliderParallaxElInner );
          sliderParallaxSet( 0, 0, sliderParallaxElCaption );
        } else {
          sliderParallaxSet( 0, 0, sliderParallaxEl );
          sliderParallaxSet( 0, 0, sliderParallaxElCaption );
        }
      }
    } else {
      $sliderParallaxEl.addClass('slider-parallax-invisible').removeClass('slider-parallax-visible');
    }

    requestAnimationFrame(function(){
      sliderParallax();
      sliderElementsFade();
    });
  } else {
    if( $sliderParallaxEl.find('.slider-inner').length > 0 ) {
      sliderParallaxSet( 0, 0, sliderParallaxElInner );
      sliderParallaxSet( 0, 0, sliderParallaxElCaption );
    } else {
      sliderParallaxSet( 0, 0, sliderParallaxEl );
      sliderParallaxSet( 0, 0, sliderParallaxElCaption );
    }
    $sliderParallaxEl.addClass('slider-parallax-visible').removeClass('slider-parallax-invisible');
  }
}

function sliderElementsFade(){
	$body = $("body")
	$sliderParallaxEl = $('.slider-parallax')

  if( $sliderParallaxEl.length < 1 ) {
    return true;
  }

  if( ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) && !isMobile.any() ) {
    let parallaxOffsetTop = sliderParallaxOffset(),
      parallaxElHeight = $sliderParallaxEl.outerHeight(),
      tHeaderOffset;

    if( $header.hasClass('transparent-header') || $body.hasClass('side-header') ) {
      tHeaderOffset = 100;
    } else {
      tHeaderOffset = 0;
    }
    $sliderParallaxEl.filter('.slider-parallax-visible').find('.slider-arrow-left,.slider-arrow-right,.slider-caption,.slider-element-fade').css({'opacity': 1 - ( ( ( yScrollPosition - tHeaderOffset ) * 1.85 ) / parallaxElHeight ) });
  } else {
    $sliderParallaxEl.find('.slider-arrow-left,.slider-arrow-right,.slider-caption,.slider-element-fade').css({'opacity': 1});
  }
}

function swiperSliderMenu(onWinLoad){
  $slider = $("#slider")
  $body = $("body")
  $header = $("#header")
  onWinLoad = typeof onWinLoad !== 'undefined' ? onWinLoad : false;
  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || ( $header.hasClass('transparent-header-responsive') && !$body.hasClass('primary-menu-open') ) ) {
    let activeSlide = $slider.find('.swiper-slide.swiper-slide-active');
    headerSchemeChanger(activeSlide, onWinLoad);
  }
}

function revolutionSliderMenu(onWinLoad){
  $slider = $("#slider")
  $body = $("body")
  $header = $("#header")
  onWinLoad = typeof onWinLoad !== 'undefined' ? onWinLoad : false;
  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || ( $header.hasClass('transparent-header-responsive') && !$body.hasClass('primary-menu-open') ) ) {
    let activeSlide = $slider.find('.active-revslide');
    headerSchemeChanger(activeSlide, onWinLoad);
  }
}

function headerSchemeChanger(activeSlide, onWinLoad){
  if( activeSlide.length > 0 ) {
    let darkExists = false,
      oldClassesArray, noOfOldClasses;
    if( activeSlide.hasClass('dark') ){
      if( oldHeaderClasses ) {
        oldClassesArray = oldHeaderClasses.split(/ +/);
      } else {
        oldClassesArray = '';
      }

      noOfOldClasses = oldClassesArray.length;

      if( noOfOldClasses > 0 ) {
        let i = 0;
        for( i=0; i<noOfOldClasses; i++ ) {
          if( oldClassesArray[i] == 'dark' && onWinLoad == true ) {
            darkExists = true;
            break;
          }
        }
      }
      $('#header.transparent-header:not(.sticky-header,.semi-transparent,.floating-header)').addClass('dark');
      if( !darkExists ) {
        $('#header.transparent-header.sticky-header,#header.transparent-header.semi-transparent.sticky-header,#header.transparent-header.floating-header.sticky-header').removeClass('dark');
      }
      $headerWrap.removeClass('not-dark');
    } else {
      if( $body.hasClass('dark') ) {
        activeSlide.addClass('not-dark');
        $('#header.transparent-header:not(.semi-transparent,.floating-header)').removeClass('dark');
        $('#header.transparent-header:not(.sticky-header,.semi-transparent,.floating-header)').find('#header-wrap').addClass('not-dark');
      } else {
        $('#header.transparent-header:not(.semi-transparent,.floating-header)').removeClass('dark');
        $headerWrap.removeClass('not-dark');
      }
    }
    if( $header.hasClass('sticky-header') ) {
      stickyMenuClass();
    }
  }
}


function stickyClass($window){
	let width = $window.width()
	let $body = $("body")
	if (width > 1200) {
		$body.addClass('device-xl')
	} else if (width < 1200 && width > 992) {
		$body.addClass('device-lg')
	} else if (width < 992 && width > 768) {
		$body.addClass('device-md')
	} else if (width < 768 && width > 576) {
		$body.addClass('device-sm')
	}else{
		$body.addClass('device-xs')
	}
}

function stickyMenu( $window, headerOffset ){
  let $header = $("#header")
  let $body = $("body")
  let logo = $('#logo')
  let stickyShrink = $header.attr('data-sticky-shrink') || 'true'
	let stickyShrinkOffset = $header.attr('data-sticky-shrink-offset') || 300
  let headerSizeCustom = !$header.hasClass('header-size-lg') && !$header.hasClass('header-size-md') && !$header.hasClass('header-size-sm') && !$header.hasClass('header-size-custom')
  windowScrT	= $window.scrollTop();
	defLogoH	= $header.attr('data-logo-height') || 80
	mobileLogoH = $header.attr('data-mobile-logo-height') || Number( defLogoH )
	stickyLogoH = $header.attr('data-sticky-logo-height') || 60
	stickyMenuP = $header.attr('data-sticky-menu-padding') || 19
	defMenuP	= $header.attr('data-menu-padding') || 39
  mobileSticky = $header.attr('data-mobile-sticky') || 'false'
  $dark_logo_url = $header.find(".standard-logo").data("dark-logo")
  $normal_logo_url = $header.find(".standard-logo").data("normal-logo")

  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
    if( windowScrT > headerOffset ) {

      if( !$body.hasClass('side-header') ) {
        $header.filter(':not(.no-sticky)').addClass('sticky-header');
        // if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
        stickyMenuClass();

        if( stickyShrink == 'true' && !$header.hasClass('no-sticky')) {
          if( ( windowScrT - headerOffset ) > Number( stickyShrinkOffset ) ) {
            $header.addClass('sticky-header-shrink');
            $header.find(".logo-navbar").attr("src", $normal_logo_url)
            if( headerSizeCustom ){
              logo.find('img').css({ 'height': Number( stickyLogoH ) });
							$header.find(".icon-menu-link").children('img').css({ 'height': Number( stickyLogoH ) });
              menuItemsSpacing( stickyMenuP );
            }
          } else {
            if (!$body.hasClass("index")) {
              $header.find(".logo-navbar").attr("src", $normal_logo_url)
            } else {
              $header.find(".logo-navbar").attr("src", $dark_logo_url)
            }
            $header.removeClass('sticky-header-shrink');
            if( headerSizeCustom ){
              logo.find('img').css({ 'height': Number( defLogoH ) });
							$header.removeClass("sticky-header")
							$header.addClass("dark")
							$header.find(".icon-menu-link").children('img').css({ 'height': Number( defLogoH ) });
              menuItemsSpacing( defMenuP );
            }
          }
        }
      }

    } else {
      removeStickyness();
      if( headerSizeCustom ){
        logo.find('img').css({ 'height': Number( defLogoH ) });
				$header.removeClass("sticky-header")
				$header.addClass("dark")
				$header.find(".icon-menu-link").children('img').css({ 'height': Number( defLogoH ) });
        menuItemsSpacing( defMenuP );
      }
    }
  }

  if( $body.hasClass('device-xs') || $body.hasClass('device-sm') || $body.hasClass('device-md') ) {
    if( mobileSticky == 'true' ) {
      if( windowScrT > headerOffset ) {
        $header.filter(':not(.no-sticky)').addClass('sticky-header');
        stickyMenuClass();
      } else {
        removeStickyness();
        responsiveMenuClass();
      }
    } else {
      removeStickyness();
    }
    if( headerSizeCustom ){
      logo.find('img').css({ 'height': Number( mobileLogoH ) });
			$header.find(".icon-menu-link").children('img').css({ 'height': Number( mobileLogoH ) });
      menuItemsSpacing( '' );
    }
  }
}

$.fn.scrollEnd = function(callback, timeout) {
	$(this).scroll(function(){
		let container = $(this);
		if (container.data('scrollTimeout')) {
			clearTimeout(container.data('scrollTimeout'));
		}
		container.data('scrollTimeout', setTimeout(callback,timeout));
	});
};

function responsiveMenuClass(){
	$header = $("#header")
	$body = $("body")
	$headerWrap = $('#header-wrap'),
	responsiveMenuClasses = $header.attr('data-responsive-class')
	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ){
		return true;
	}

	let newClassesArray = '';

	if( responsiveMenuClasses ) {
		newClassesArray = responsiveMenuClasses.split(/ +/);
	}

	let noOfNewClasses = newClassesArray.length;

	if( noOfNewClasses > 0 ) {
		let i = 0;
		for( i=0; i<noOfNewClasses; i++ ) {
			if( newClassesArray[i] == 'not-dark' ) {
				$header.removeClass('dark');
				$headerWrap.addClass('not-dark');
			} else if( newClassesArray[i] == 'dark' ) {
				$headerWrap.removeClass('not-dark force-not-dark');
				if( !$header.hasClass( newClassesArray[i] ) ) {
					$header.addClass( newClassesArray[i] );
				}
			} else if( !$header.hasClass( newClassesArray[i] ) ) {
				$header.addClass( newClassesArray[i] );
			}
		}
	}
}

function windowscroll($window){
  $header = $("#header")
  $headerWrap = $('#header-wrap')
  $pagemenu = $('#page-menu');
  $pageMenuClone = '';
  $pageMenuWrap = $pagemenu.find('#page-menu-wrap');
	pageMenuOffset = 0
  mobileSticky = $header.attr('data-mobile-sticky') || 'false'

  if( $header.length > 0 ) {
    headerOffset = $header.offset().top;
    $headerWrap.addClass('position-absolute');
    headerWrapOffset = $headerWrap.offset().top;
    $headerWrap.removeClass('position-absolute');
  }

  let headerDefinedOffset = $header.attr('data-sticky-offset');
  if( typeof headerDefinedOffset !== 'undefined' ) {
    if( headerDefinedOffset == 'full' ) {
      headerWrapOffset = $window.height();
      let headerOffsetNegative = $header.attr('data-sticky-offset-negative');
      if( typeof headerOffsetNegative !== 'undefined' ) {
        headerWrapOffset = headerWrapOffset - headerOffsetNegative - 1;
      }
    } else {
      headerWrapOffset = Number(headerDefinedOffset);
    }
  } else {
    if( headerWrapOffset === 'undefined' ) {
      headerWrapOffset = headerOffset;
    }
  }

  let pageMenuWrap	= $pagemenu.find('#page-menu-wrap'),
    offset			= $headerWrap.outerHeight(),
    head			= document.head || document.getElementsByTagName('head')[0],
    style			= document.createElement('style'),
    css;

  if( $pagemenu.length > 0 ) {
    $pageMenuClone.css({ 'height': $pagemenu.find('#page-menu-wrap').outerHeight() });
    setTimeout( function(){
      if( $header.length > 0 && !$header.hasClass('no-sticky') ) {
        if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || mobileSticky == 'true' ) {
          pageMenuOffset = $pagemenu.offset().top - $headerWrap.outerHeight();
          head.appendChild(style);
          css = '#page-menu.sticky-page-menu:not(.dots-menu) #page-menu-wrap { top: '+ offset +'px; }';

          style.type = 'text/css';
          style.appendChild(document.createTextNode(css));
        } else {
          pageMenuOffset = $pagemenu.offset().top;
        }
      } else {
        pageMenuOffset = $pagemenu.offset().top;
      }
    }, 1000);
  }

  stickyMenu( $window, headerWrapOffset );
  stickyPageMenu( pageMenuOffset );

  window.addEventListener( 'scroll', function(){

    goToTopScroll($window);
    $('body.open-header.close-header-on-scroll').removeClass("side-header-open");
    stickyMenu( $window, headerWrapOffset );
    stickyPageMenu( pageMenuOffset );

  });

  window.addEventListener( 'DOMContentLoaded', onScrollSliderParallax, false );
	$window.scrollEnd( function(){
    let headerHeight = $headerWrap.outerHeight();
    if( $pagemenu.length > 0 && $header.length > 0 && !$header.hasClass('no-sticky') ) {
      if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
        $pagemenu.filter('.sticky-page-menu:not(.dots-menu,.no-sticky)').find( $pageMenuWrap ).css({ 'top': headerHeight +'px' });
      }
    }
  }, 500 );

}

function onScrollSliderParallax(){
	sliderParallax()
	sliderElementsFade()
}
function stickyPageMenu( pageMenuOffset ){
  $body = $("body")
  $header = $("#header")
  $headerWrap = $('#header-wrap');
  $pagemenu = $('#page-menu');
  $pageMenuClone = '';
  $pageMenuWrap = $pagemenu.find('#page-menu-wrap');
  if( $window.scrollTop() > pageMenuOffset ) {
    if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
      $pagemenu.filter(':not(.dots-menu,.no-sticky)').addClass('sticky-page-menu');
      let headerHeight = $headerWrap.outerHeight();
      if( $header.length > 0 && !$header.hasClass('no-sticky') ) {
        $pagemenu.filter('.sticky-page-menu:not(.dots-menu,.no-sticky)').find( $pageMenuWrap ).css({ 'top': headerHeight +'px' });
      }
    } else if( $body.hasClass('device-sm') || $body.hasClass('device-xs') || $body.hasClass('device-md') ) {
      if( $pagemenu.attr('data-mobile-sticky') == 'true' ) {
        $pagemenu.filter(':not(.dots-menu,.no-sticky)').addClass('sticky-page-menu');
      }
    }
  } else {
    $pagemenu.removeClass('sticky-page-menu');
    $pagemenu.find( $pageMenuWrap ).css({ 'top': '' });
  }
}

function goToTop(){
  $goToTopEl = $('#gotoTop')
  let elementScrollSpeed = $goToTopEl.attr('data-speed'),
    elementScrollEasing = $goToTopEl.attr('data-easing');

  if( !elementScrollSpeed ) { elementScrollSpeed = 700; }
  if( !elementScrollEasing ) { elementScrollEasing = 'easeOutQuad'; }

  $goToTopEl.off( 'click' ).on( 'click', function() {
    $('body,html').stop(true).animate({
      'scrollTop': 0
    }, Number( elementScrollSpeed ), elementScrollEasing );
    return false;
  });
}

function goToTopScroll($window){
  $goToTopEl = $('#gotoTop')
  let elementMobile = $goToTopEl.attr('data-mobile'),
    elementOffset = $goToTopEl.attr('data-offset');

  if( !elementOffset ) { elementOffset = 450; }

  if( elementMobile != 'true' && ( $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) { return true; }

  if( $window.scrollTop() > Number(elementOffset) ) {
    $goToTopEl.fadeIn();
    $body.addClass('gototop-active');
  } else {
    $goToTopEl.fadeOut();
    $('.header-wrap-clone').remove()
    $body.removeClass('gototop-active');
  }
}

function includeOffset(){
  $header = $("#header")
  $headerInc = $('.include-header')
  if( $headerInc.length < 1 ) {
    return true;
  }

  let headerInc = $header.outerHeight();
  if( $header.hasClass('floating-header') || $headerInc.hasClass('include-topbar') ) {
    headerInc = headerInc + $header.offset().top;
  }
  $headerInc.css({ 'margin-top': -headerInc });
  sliderParallax();
}

function stickyMenuClass(){
  let $header = $("#header")
  let $headerWrap = $('#header-wrap')
  let stickyMenuClasses = $header.attr('data-sticky-class')
  let newClassesArray = '';

  if( stickyMenuClasses ) {
    newClassesArray = stickyMenuClasses.split(/ +/);
  }

  let noOfNewClasses = newClassesArray.length;

  if( noOfNewClasses > 0 ) {
    let i = 0;
    for( i=0; i<noOfNewClasses; i++ ) {
      if( newClassesArray[i] == 'not-dark' ) {
        $header.removeClass('dark');
        $headerWrap.filter(':not(.not-dark)').addClass('not-dark');
      } else if( newClassesArray[i] == 'dark' ) {
        $headerWrap.removeClass('not-dark force-not-dark');
        if( !$header.hasClass( newClassesArray[i] ) ) {
          $header.addClass( newClassesArray[i] );
        }
      } else if( !$header.hasClass( newClassesArray[i] ) ) {
        $header.addClass( newClassesArray[i] );
      }
    }
  }
}

function menuItemsSpacing( spacing ) {
  let $body = $("body")
  let primaryMenu = $('.primary-menu');
	let	primaryMenuMainItems = primaryMenu.find('.menu-container:not(mobile-primary-menu):not(.custom-spacing)').children('.menu-item').children('.menu-link')
  let item	= primaryMenuMainItems;
}

function removeStickyness(){
  let $header = $("#header")
  let $headerWrap = $('#header-wrap')
  let oldHeaderClasses = $header.attr('class')
	let oldHeaderWrapClasses = $headerWrap.attr('class')
	let $body = $("body")
	responsiveMenuClasses = $header.attr('data-responsive-class')

  if( $header.hasClass('sticky-header') ){
    $header.removeClass('sticky-header');
    $header.removeClass().addClass(oldHeaderClasses);
    $headerWrap.removeClass().addClass(oldHeaderWrapClasses);
    if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
    swiperSliderMenu();
    revolutionSliderMenu();
  }
  if( ( $body.hasClass('device-sm') || $body.hasClass('device-xs') || $body.hasClass('device-md') ) && ( typeof responsiveMenuClasses === 'undefined' ) ) {
    $header.removeClass().addClass(oldHeaderClasses);
    $headerWrap.removeClass().addClass(oldHeaderWrapClasses);
    if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
  }
}

function menufunctions(){
  primaryMenu = $('.primary-menu')
  $body = $("body")
  $headerInc = $('.include-header')
  let menuItemSubs		= $( '.menu-item:has(.sub-menu-container)' ),
    menuItemSubsLinks	= menuItemSubs.children( '.menu-link' ),
    submenusT			= '.mega-menu-content, .sub-menu-container',
    submenus			= $( submenusT ),
    menuItemT			= '.menu-item',
    subMenuT			= '.sub-menu',
    menuSpeed			= primaryMenu.attr( 'data-trigger-speed' ) || 200,
    subMenuTriggerT		= '.sub-menu-trigger',
    menuItemTrigger;

  menuSpeed = Number( menuSpeed );

  menuItemTrigger	= menuItemSubs.children( subMenuTriggerT );

  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
    primaryMenu.find( submenus ).css({ 'display': '' });
  } else {
    $headerInc.css({ 'margin-top': '' });
  }

  if( ( $body.hasClass('overlay-menu') && primaryMenu.hasClass('on-click') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
    menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
      let triggerEl = $(this);
      triggerEl.parents( subMenuT ).siblings().find( submenus ).stop( true, true ).slideUp( menuSpeed );
      triggerEl.parent( menuItemT ).children( submenusT ).stop( true, true ).slideToggle( menuSpeed );
      e.preventDefault();
    });
  } else if( ( $body.hasClass('side-header') && primaryMenu.hasClass('on-click') ) || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
    menuItemTrigger.removeClass('icon-rotate-90');
    $( menuItemT ).find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed , function(){
      $body.toggleClass("primary-menu-open", false);
    });

    menuItemTrigger = menuItemTrigger.add( menuItemSubsLinks.filter('[href^="#"]') );

    menuItemTrigger.off( 'click' ).on( 'click', function(e){
      let triggerEl = $(this);
      triggerEl.parents( subMenuT ).siblings().find( subMenuTriggerT ).removeClass('icon-rotate-90');
      triggerEl.parents( subMenuT ).siblings().find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed );
      triggerEl.parent( menuItemT ).children( submenusT ).filter(':not(:animated)').stop( true, true ).slideToggle( menuSpeed );

      let subMenuTriggerEl = triggerEl.parent( menuItemT ).children( subMenuTriggerT );

      if( !subMenuTriggerEl.hasClass( 'icon-rotate-90' ) ) {
        subMenuTriggerEl.addClass('icon-rotate-90');
      } else {
        subMenuTriggerEl.removeClass('icon-rotate-90');
      }

      e.preventDefault();
    });
  } else if( ( $body.hasClass('overlay-menu') || $body.hasClass('side-header') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
    primaryMenu.find( submenus ).stop( true, true ).slideUp( menuSpeed );
    $( menuItemT ).hover( function(e){
      $(this).children( submenusT ).stop( true, true ).slideDown( menuSpeed );
    }, function(){
      $(this).children( submenusT ).stop( true, true ).slideUp( menuSpeed );
    });
  } else {
    if( primaryMenu.hasClass('on-click') ) {
      menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
        let triggerEl = $(this);
        triggerEl.parents( subMenuT ).siblings().find( submenus ).removeClass('d-block');
        triggerEl.parent( menuItemT ).children( submenusT ).toggleClass('d-block');
        e.preventDefault();
      });
    }
  }

  if( $('.top-links').hasClass('on-click') || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
    $('.top-links-item:has(.top-links-sub-menu,.top-links-section) > a').on( 'click', function(e){
      $(this).parents('li').siblings().find('.top-links-sub-menu,.top-links-section').removeClass('d-block');
      $(this).parent('li').children('.top-links-sub-menu,.top-links-section').toggleClass('d-block');
      e.preventDefault();
    });
  }

  menuInvert( $('.top-links-section') );

  $('#primary-menu-trigger').on( 'click', function() {
    if($body.hasClass("primary-menu-open")){
      normalMenuLink()
    }else{
      normalMenuLink()
      mobileMenuLink()
    }

    if( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) {
      if( primaryMenu.find('.mobile-primary-menu').length > 0 ) {
        $( '.primary-menu:not(.mobile-menu-off-canvas) .mobile-primary-menu' ).stop( true, true ).slideToggle( menuSpeed );
        $( '.primary-menu.mobile-menu-off-canvas .mobile-primary-menu' ).toggleClass('d-block');
      } else {
        $( '.primary-menu:not(.mobile-menu-off-canvas) .menu-container' ).stop( true, true ).slideToggle( menuSpeed );
        $( '.primary-menu.mobile-menu-off-canvas .menu-container' ).toggleClass('d-block');
      }
    }
    $body.toggleClass("primary-menu-open");
    return false;
  });

  $( '.menu-container:not(.mobile-primary-menu)' ).css({ 'display': '' });
  if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
    primaryMenu.find('.mobile-primary-menu').removeClass('d-block');
  }

}

function menuInvert(subMenuEl){
  let submenus = subMenuEl || $( '.mega-menu-content, .sub-menu-container, .top-links-section' );

  submenus.children().css({ 'display': 'block' });
  submenus.css({ 'display': 'block' });
  submenus.each( function( index, element ){
    let $menuChildElement = $(element),
      menuChildOffset = $menuChildElement.offset(),
      menuChildWidth = $menuChildElement.width();

    if(windowWidth - (menuChildWidth + menuChildOffset.left) < 0) {
      $menuChildElement.addClass('menu-pos-invert');
    }
  });
  submenus.children().css({ 'display': '' });
  submenus.css({ 'display': '' });
}

function mobileMenuLink(){
  $.each($(".icon-menu-link"), function( key, value ) {
    let $element = $(value)
    let $image = $element.find("img")

    $element.append("<div>" + $image.attr("alt") +"</div>")
    $image.addClass("d-none")
  });
}

function normalMenuLink(){
  $.each($(".icon-menu-link"), function( key, value ) {
    let $element = $(value)
    let $image = $element.find("img")

    $element.find("div").remove()
    $image.removeClass("d-none")
  });
}
