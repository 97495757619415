/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("jquery")
require("@rails/ujs").start();
require("turbolinks").start();
require("bootstrap")
import "../stylesheets/dashboard_styles";
import "../stylesheets/landings";
import "@fortawesome/fontawesome-free/js/all";
import moment from 'moment'

window.moment = moment

document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
        setTimeout(function(){
            $("#notice").fadeOut(1000)
            $("#alert").fadeOut(1000)
        }, 1500)
    })
})
require("../plugins/perfect-scrollbar.min")
require("../plugins/jquery.scrollTo.min")
require("../landings/functions")
require("../landings/animations")
require("../dashboard/quoters/quoters")
require("../landings/registration")
require("../plugins/select.from.library")
require("../plugins/select2.full")
require("../application/validations")
require("../application/shopping")
require("../application/queue_initializer")

require("trix")
require("@rails/actiontext")
