document.addEventListener("turbolinks:load", function() {
  $("#quoters-container").each(function(options){
    let url = $("#quoters-container").data("url")
    quoterRequest(url)
    $('#regimes').on('change', function (e) {
      regimes_number = $(this).val();
      activies_number = $('#activities-number').val();
      months = $('#months').val();
      console.log(regimes_number, activies_number, months)
      quoterRequest(`${url}?regimes_number=${regimes_number}&activities_number=${activies_number}&months=${months}`)
    });

    $('#activities-number').on('change', function (e) {
      regimes_number = $('#regimes').val();
      activies_number = $(this).val();
      months = $('#months').val();
      console.log(regimes_number, activies_number, months)
      quoterRequest(`${url}?regimes_number=${regimes_number}&activities_number=${activies_number}&months=${months}`)
    });

    $("input[name='nacionalidadCliente']").val("Extranjero")

    $('#months').on('change', function (e) {
      regimes_number = $('#regimes').val();
      activies_number = $('#activities-number').val();
      months = $(this).val();
      console.log(regimes_number, activies_number, months)
      quoterRequest(`${url}?regimes_number=${regimes_number}&activities_number=${activies_number}&months=${months}`)
    });

  });
});


function quoterRequest(url){
  $("#products-container").html("")
  $.ajax({
    url: url,
    type: 'GET',
    success: function(res) {
      $("#products-container").append(productCards(res.products))
    },
    error: function(res) {
    }
  });
}

function productCards(products){
  let product_cards = ""
  if(!products.length){
    return
  }

  products.forEach(function(element, index) {
    product_cards += productCard(element, index);
  });

  return product_cards
}

function productCard(product, index){
  let products_classes = ["primary", "secondary", "primary", "secondary"]
  return `
  <div class="col-12 col-md-4 mb-5">
    <div class="pricing-box pricing-simple p-2 bg-light border-top border-${products_classes[index]} text-center">
      <div class="pricing-title">
        <h3>${product.name}</h3>
        <div class="pricing-features border-0 bg-transparent pt-1 pb-1">
          <ul>
            <li><strong>${product.quantity} ${product.product_unit_name}</strong></li>
            <li>Hasta <strong>${product.regimes_number}</strong> regimenes</li>
            <li>Hasta <strong>${product.activities_number}</strong> actividades</li>
          </ul>
          ${product.included_options_html}
        </div>
      </div>
      <div class="pricing-price  pt-2 pb-2">
        <span class="price-unit">$</span>${parseFloat(product.unit_price).toFixed(2)}
      </div>
      <div class="pricing-action  pt-2 pb-2">
        <a href="#shopping-modal" data-toggle="modal"
          data-target="#shopping-modal" data-product-name="${product.name}" data-product-id="${product.id}"
          data-product-price="${parseFloat(product.unit_price).toFixed(2)}"
          class="btn button-3d gradient-sky btn-lg">
          <i class="fab fa-cc-paypal"></i> Comprar ahora
        </a>
      </div>
    </div>
  </div>
  `
}