document.addEventListener('turbolinks:load', function() {
  $("input.numbers-only").each(function() {
    numbersOnlyTextField(this);
  });

  $(".password-field").find(".cursor-pointer").on("click", function(e){
    passwordFields(this)
  })
});

function numbersOnlyTextField(input) {
  ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function(event) {
    return input.addEventListener(event, function() {
      if (/^-?\d*$/.test(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        return this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        return this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        return this.value = '';
      }
    });
  });
}

function passwordFields(button) {
  $button = $(button)
  $container = $button.closest(".password-field")
  $passwordField = $container.find("input.form-control")
  $showPasswordIcon = "<i class='far fa-eye'></i>"
  $hidePasswordIcon = "<i class='fas fa-eye-slash'></i>"

  if ($passwordField.attr("type") == "text") {
    $passwordField.attr("type", "password")
    $button.find(".input-group-text").html("").append($showPasswordIcon)
  } else {
    $passwordField.attr("type", "text")
    $button.find(".input-group-text").html("").append($hidePasswordIcon)
  }
}