const bindErrorMessages = (form, errors, model_name) => {
  $.each(form.find('.form-group'), function(index, element) {
    if($(element).find("small.form-text").length) {
      return
    }else {
      $(element).append($('<small class="form-text text-danger">&nbsp;</small>'));
    }
  });

  Object.keys(errors).forEach(function(key) {
    let $formGroup, $input, errorMessage;
    $input = $("#" + model_name + "_" + (nameInput(key)));
    $formGroup = $input.closest('.form-group') || $input.closest('.custom-control');
    $label = $formGroup.find("label")

    $input.removeClass("is-invalid")
    $label.removeClass("text-danger")
    $formGroup.find('small.form-text').text(null);

    errorMessage = errors[key].join(', ');
    if(errorMessage){
      $input.addClass("is-invalid");
      $label.addClass("text-danger")
      $formGroup.find('small.form-text').text(errorMessage);
    }
  });
}

function nameInput(key) {
  let keyDetail;
  keyDetail = key.split('.');
  if (keyDetail.length > 1) {
    return keyDetail[0] + "_attributes_" + keyDetail[1];
  } else {
    return keyDetail[0];
  }
}
exports.bindErrorMessages = bindErrorMessages;